/* .c3-tooltip-container {
  max-height: none !important;
}

.c3-tooltip {
  box-shadow: none;
  width: 100%;
}

.c3-tooltip tr {
  border-left: none !important;
  border-right: none !important;
}

.c3-tooltip td.name {
  border-left: none !important;
}

.c3-tooltip tr:first-child {
  display: none;
}

.c3-tooltip-container .c3-chart-line {
  opacity: 1 !important;
} */




 

svg rect.foo {
  fill: white;
  stroke-width: 0.5;
  stroke: grey;
}
.c3-tooltip {
    color: #000;
	background:#000 !important;
	display:block;
}

.c3 .c3-axis-x path, .c3 .c3-axis-x line {
    stroke: #eee;
}
.c3 .c3-axis-y path, .c3 .c3-axis-y line {
    stroke: #eee;
}

.c3-grid line {
  stroke: #eee !important;
}

.c3-line {
    stroke-width: 2px !important;
	 
}
/*
.c3-circle {
    fill: red !important;
} */

.card-body.text-center {
    padding: 13px 2px !important;
}
.mar-40{ margin-top:10px;}
 

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #000 !important;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777;
  -moz-box-shadow: 7px 7px 12px -9px #777;
  box-shadow: 7px 7px 12px -9px #777;
  opacity: .9
}

.c3-tooltip tr {
  border:0px solid #CCC !important;
}

.c3-tooltip th {
  background-color: #000 !important;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left !important;
  color: #FFF !important;
}

.c3-tooltip td {
  font-size: 12px;
  text-align: left !important;
  padding: 6px 6px;
  background-color: #000 !important;
  border-left: 0px dotted #999  !important;
  color: #FFF !important;
}

.c3-tooltip td>span {
	text-align: left !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px
}


.drop-1{ position:absolute; right:-50px; bottom:40px;}